import React from 'react'
import Layout from '../components/Layout'
import SEO from '../components/SEO'
import * as errorStyles from './error.module.css'

const Error = () => {
    return (
        <Layout>
            <SEO title='Vigane leht'/>
            <main className={errorStyles.errorpage}>
                <section>
                    <h1>404</h1>
                    <h3>Lehekülge ei leitud</h3>
                </section>
            </main>
        </Layout>
    )
}

export default Error